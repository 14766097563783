import { CellEmpty } from '@t15-ui-kit/cellEmpty';
import { DChip } from '@t15-ui-kit/dChip';
import { ColorsShadesEnum } from '@t15-ui-kit/enums';
import { Grid } from '@t15-ui-kit/grid/Grid';
import { Typography } from '@t15-ui-kit/typography';
import { MEETING_LIST_TYPE } from 'common/enums/rpc/lime/meeting-list-type.enum';
import { useRxBind } from 'common/utils/hooks/use-rx-bind';
import { coreRootRouting } from 'core/providers/core-root-routing.provider';
import { userContext } from 'core/providers/user-context-provider';
import { MeetingListCompositionContext } from 'modules/meeting/views/list/contexts/meeting-list-composition.context';
import { useContext } from 'react';

export function TypeOfMeetingsSelectSection(): JSX.Element {
  const {
    type,
    store: { isFiltered$ }
  } = useContext(MeetingListCompositionContext);

  const user = useContext(userContext);

  const isFiltered = useRxBind(isFiltered$);

  return (
    <>
      <Grid container elementOffset={['none', 'xs']}>
        <Grid>
          <DChip
            text={
              isFiltered ? (
                <Typography color={ColorsShadesEnum.Grey400} size="s" weight="500">
                  Мои
                </Typography>
              ) : (
                'Мои'
              )
            }
            isSelected={!isFiltered && type === MEETING_LIST_TYPE.MY}
            onClick={isFiltered ? void 0 : (): void => coreRootRouting.goToMeetings({ type: MEETING_LIST_TYPE.MY })}
          />
        </Grid>
        {!user?.specialPartner && (
          <Grid>
            <DChip
              text={
                isFiltered ? (
                  <Typography color={ColorsShadesEnum.Grey400} size="s" weight="500">
                    Свободные
                  </Typography>
                ) : (
                  'Свободные'
                )
              }
              isSelected={!isFiltered && type === MEETING_LIST_TYPE.AVAILABLE}
              onClick={
                isFiltered ? void 0 : (): void => coreRootRouting.goToMeetings({ type: MEETING_LIST_TYPE.AVAILABLE })
              }
            />
          </Grid>
        )}
      </Grid>
      <CellEmpty height="30" />
    </>
  );
}
