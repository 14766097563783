import { deserialize, JsonName } from 'tserialize';

export class UserModel {
  @JsonName()
  user!: string;

  @JsonName()
  name!: string;

  @JsonName()
  id!: number;

  @JsonName()
  code!: string;

  // TODO: строго описать возможные вариант прав
  @JsonName()
  userRights!: Array<string>;

  @JsonName()
  webRestriction: boolean = false;

  @JsonName()
  fileSelectRestriction: boolean = false;

  @JsonName('deleted')
  isDeleted: boolean = false;

  @JsonName()
  partner?: boolean;

  @JsonName()
  specialPartner?: boolean;

  constructor(data: UserModel) {
    Object.assign(this, data);
  }

  static fromServer(data: object): UserModel {
    return deserialize(data, UserModel);
  }
}
