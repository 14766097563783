import { History as HistoryFilled } from '@tochka-modules/t15-ui-kit-icons/30/Filled/History';
import { Man as ManFilled } from '@tochka-modules/t15-ui-kit-icons/30/Filled/Man';
import { Person as PersonFilled } from '@tochka-modules/t15-ui-kit-icons/30/Filled/Person';
import { Star as StarFilled } from '@tochka-modules/t15-ui-kit-icons/30/Filled/Star';
import { Men as ManStroked } from '@tochka-modules/t15-ui-kit-icons/30/Stroked/Men';
import { Person as PersonStroked } from '@tochka-modules/t15-ui-kit-icons/30/Stroked/Person';
import { Star as StarStroked } from '@tochka-modules/t15-ui-kit-icons/30/Stroked/Star';
import { Watch as WatchStroked } from '@tochka-modules/t15-ui-kit-icons/30/Stroked/Watch';
import { MainPageLink } from 'common/components/ui/main-page';
import { MEETING_LIST_TYPE } from 'common/enums/rpc/lime/meeting-list-type.enum';
import { CORE_ROOT_ROUTING_PATH } from 'core/enums/core-root-routing.enum';
import { UserRight } from 'core/enums/user-right.enum';
import { coreRootRouting } from 'core/providers/core-root-routing.provider';
import { userContext } from 'core/providers/user-context-provider';
import { useContext } from 'react';
import { generatePath, useRouteMatch } from 'react-router';

const pathsToMatch = [CORE_ROOT_ROUTING_PATH.MEETINGS, CORE_ROOT_ROUTING_PATH.SPECIAL, CORE_ROOT_ROUTING_PATH.ADMIN];

export function LinksRootSection(): JSX.Element {
  const { path, url } = useRouteMatch(pathsToMatch) ?? {};
  const user = useContext(userContext);

  const isAdmin = user?.userRights?.includes(UserRight.ADMIN);
  const isSpecial = user?.userRights?.includes(UserRight.SPECIAL);

  const meetingsUrl = generatePath(CORE_ROOT_ROUTING_PATH.MEETINGS, {
    type: user?.specialPartner ? MEETING_LIST_TYPE.MY : MEETING_LIST_TYPE.AVAILABLE
  });
  const specialUrl = generatePath(CORE_ROOT_ROUTING_PATH.SPECIAL);
  const historyUrl = generatePath(CORE_ROOT_ROUTING_PATH.MEETINGS, { type: MEETING_LIST_TYPE.HISTORY });
  const adminUrl = generatePath(CORE_ROOT_ROUTING_PATH.ADMIN);

  return (
    <>
      <MainPageLink
        href={meetingsUrl}
        icon={<PersonStroked />}
        activeIcon={<PersonFilled />}
        isActive={path === CORE_ROOT_ROUTING_PATH.MEETINGS && url !== historyUrl}
        onClick={(): void => coreRootRouting.goTo(meetingsUrl)}
      >
        Встречи
      </MainPageLink>

      <MainPageLink
        href={historyUrl}
        icon={<WatchStroked />}
        activeIcon={<HistoryFilled />}
        isActive={url === historyUrl}
        onClick={(): void => coreRootRouting.goTo(historyUrl)}
      >
        История
      </MainPageLink>

      {isSpecial && (
        <MainPageLink
          href={specialUrl}
          icon={<StarStroked />}
          activeIcon={<StarFilled />}
          isActive={url === specialUrl}
          onClick={(): void => coreRootRouting.goToSpecial()}
        >
          Управление
        </MainPageLink>
      )}

      {isAdmin && (
        <MainPageLink
          href={adminUrl}
          icon={<ManStroked />}
          activeIcon={<ManFilled />}
          isActive={url === adminUrl}
          onClick={(): void => coreRootRouting.goToSellers()}
        >
          Админка
        </MainPageLink>
      )}
    </>
  );
}
