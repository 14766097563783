import { Pencil } from '@tochka-modules/t15-ui-kit-icons/24/Stroked/Pencil';
import { LikeNavigator, LikeNavigatorAction, LikeNavigatorRow } from 'common/components/ui/like-navigator';
import { MEETING_STATUS } from 'common/enums/rpc/lime/meeting-status.enum';
import { formatDate } from 'common/utils/formatters/format-date';
import { formatTimeSlot } from 'common/utils/formatters/format-time-slot';
import { useRxBind } from 'common/utils/hooks/use-rx-bind';
import { userContext } from 'core/providers/user-context-provider';
import { MeetingSingleCompositionContext } from 'modules/meeting/views/single/contexts/meeting-single-composition.context';
import { MEETING_SINGLE_ACTION } from 'modules/meeting/views/single/enums/meeting-single-action.enum';
import { useCallback, useContext } from 'react';

export function AddressAndDatetimeOfMeetingSection(): JSX.Element {
  const {
    store,
    addressAndDatetime: {
      store: { date$, time$, address$ }
    }
  } = useContext(MeetingSingleCompositionContext);

  const user = useContext(userContext);

  const status = useRxBind(store.status$);
  const address = useRxBind(address$);
  const date = useRxBind(date$);
  const time = useRxBind(time$);

  const handleClickEdit = useCallback((): void => {
    store.action = MEETING_SINGLE_ACTION.ADDRESS_AND_DATETIME_OF_MEETING;
  }, [store]);

  return (
    <LikeNavigator
      title={'Время и\xA0место встречи'}
      actions={
        status === MEETING_STATUS.DRAFT && !user?.specialPartner ? (
          <LikeNavigatorAction icon={<Pencil />} onClick={handleClickEdit} />
        ) : (
          void 0
        )
      }
    >
      <LikeNavigatorRow title="Адрес встречи">{address ? address : 'Не\xA0назначено'}</LikeNavigatorRow>
      <LikeNavigatorRow title={'Дата и\xA0время'}>
        {date || time
          ? [date ? formatDate(date) : null, time ? formatTimeSlot(time) : null].filter(Boolean).join(' в ')
          : 'Не\xA0назначено'}
      </LikeNavigatorRow>
    </LikeNavigator>
  );
}
