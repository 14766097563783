import { CellEmpty } from '@t15-ui-kit/cellEmpty';
import { useMobile } from '@t15-ui-kit/media';
import { Forward } from '@tochka-modules/t15-ui-kit-icons/24/Stroked/Forward';
import { ActionPanel, ActionPanelAction, TActionPanelActionProps } from 'common/components/ui/action-panel';
import { MEETING_STATUS } from 'common/enums/rpc/lime/meeting-status.enum';
import { userContext } from 'core/providers/user-context-provider';
import { SpecialSingleCompositionContext } from 'modules/special/views/single/contexts/special-single-composition.context';
import { SPECIAL_SINGLE_ACTION } from 'modules/special/views/single/enums/special-single-action.enum';
import { ReactElement, useCallback, useContext } from 'react';

export function ActionsSpecialMeetingSection(): JSX.Element {
  const isMobile = useMobile();

  const user = useContext(userContext);

  const {
    data: { status },
    store
  } = useContext(SpecialSingleCompositionContext);

  const handleClickAssignToSeller = useCallback((): void => {
    store.action = SPECIAL_SINGLE_ACTION.ASSIGN_SPECIAL_MEETING_TO_SELLER;
  }, [store]);

  const handleClickAssignToCourier = useCallback((): void => {
    store.action = SPECIAL_SINGLE_ACTION.ASSIGN_SPECIAL_MEETING_TO_COURIER;
  }, [store]);

  const actions: Array<ReactElement<TActionPanelActionProps>> = [];

  if (status === MEETING_STATUS.DRAFT) {
    actions.push(
      <ActionPanelAction key="assign-to-seller" icon={<Forward />} onClick={handleClickAssignToSeller}>
        Назначить на партнера
      </ActionPanelAction>
    );

    if (!user?.specialPartner) {
      actions.push(
        <ActionPanelAction key="assign-to-courier" icon={<Forward />} onClick={handleClickAssignToCourier}>
          Передать курьеру
        </ActionPanelAction>
      );
    }
  }

  if (!actions.length) {
    return <></>;
  }

  return (
    <>
      <ActionPanel>{actions}</ActionPanel>
      <CellEmpty height={isMobile ? '15' : '30'} />
    </>
  );
}
